import ApexCharts, { ApexOptions } from 'apexcharts'
import React, { useEffect, useRef } from 'react'
import { getCSS } from '../../../../../_metronic/assets/ts/_utils'
import { useThemeMode } from '../../../../../_metronic/partials'
import { TrendTreatment } from '../../core/_models'

type Props = {
  className: string
  data: TrendTreatment[] | undefined
}

const TreatmentAnalysisPieChart: React.FC<Props> = ({className, data}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, data))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, data])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3'>Treatment</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '290px'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export { TreatmentAnalysisPieChart }

function getChartOptions(height: number, data: TrendTreatment[] | undefined): ApexOptions {
  let arrayOfSeries: any = []
  let arrayOfLabels: any = []
  if (data !== undefined) {
    arrayOfSeries = data?.map((obj) => (obj.total_sales !== null ? obj.total_sales : 0))
    arrayOfLabels = data?.map((obj) => (obj.product_name !== null ? obj.product_name : 'undefined'))
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
  }

  return {
    series: arrayOfSeries,
    chart: {
      // width: 380,
      type: 'pie',
    },
    labels: arrayOfLabels,
    legend: {
      position: 'top',
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return 'Rp. ' + numberWithCommas(val)
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          // chart: {
          //   width: 200,
          // },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  }
}
