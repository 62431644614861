/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useQueryResponseLoading, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {PaginationState} from '../../../../../../_metronic/helpers'
import {useMemo, useState} from 'react'
import CustomPagination from '@/_metronic/partials/layout/pagination/CustomPagination'

const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous'
  }

  if (label === 'Next &raquo;') {
    return 'Next'
  }

  return label
}

const UsersListPagination = () => {
  /**
   * Dummy Pagination State
   */
  const [page, setPage] = useState<number | undefined | null>(1)
  // const pagination = useQueryResponsePagination()
  // const isLoading = useQueryResponseLoading()
  // const {updateState} = useQueryRequest()
  const updatePage = (page: number | undefined | null) => {
    setPage(page)
    // if (!page || isLoading || pagination.page === page) {
    //   return
    // }

    // updateState({page, items_per_page: pagination.items_per_page || 10})
  }

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <CustomPagination
            totalCount={22}
            pageSize={10}
            currentPage={page || 1}
            onPageChange={updatePage}
          />
        </div>
      </div>
    </div>
  )
}

export {UsersListPagination}
