import {ID, Response} from '../../../../../_metronic/helpers'
export type IRevenueList = {
  brand: string;
  sub_brand: string;
  outlet: string;
  period: string;
  year: string;
  actual_sales: string;
  forecast: string;
  actual_treatments: string;
  forecast2: string;
}

export type UsersQueryResponse = Response<Array<IRevenueList>>

export const IRevenueListDummyState: IRevenueList[] = [
  {
    brand: "Alaya",
    sub_brand: "Alaya",
    outlet: "Hotel Vio Bandung",
    period: "January",
    year: "2023",
    actual_sales: "1.500.000",
    forecast: "1.250.000",
    actual_treatments: "1.000.000",
    forecast2: "1.250.000",
  },
  {
    brand: "Alaya",
    sub_brand: "Alaya",
    outlet: "Hotel Vio Bandung",
    period: "January",
    year: "2023",
    actual_sales: "1.500.000",
    forecast: "1.250.000",
    actual_treatments: "1.000.000",
    forecast2: "1.250.000",
  },
  {
    brand: "Alaya",
    sub_brand: "Alaya",
    outlet: "Hotel Vio Bandung",
    period: "January",
    year: "2023",
    actual_sales: "1.500.000",
    forecast: "1.250.000",
    actual_treatments: "1.000.000",
    forecast2: "1.250.000",
  },
  {
    brand: "Alaya",
    sub_brand: "Alaya",
    outlet: "Hotel Vio Bandung",
    period: "January",
    year: "2023",
    actual_sales: "1.500.000",
    forecast: "1.250.000",
    actual_treatments: "1.000.000",
    forecast2: "1.250.000",
  },
  {
    brand: "Alaya",
    sub_brand: "Alaya",
    outlet: "Hotel Vio Bandung",
    period: "January",
    year: "2023",
    actual_sales: "1.500.000",
    forecast: "1.250.000",
    actual_treatments: "1.000.000",
    forecast2: "1.250.000",
  },
  {
    brand: "Alaya",
    sub_brand: "Alaya",
    outlet: "Hotel Vio Bandung",
    period: "January",
    year: "2023",
    actual_sales: "1.500.000",
    forecast: "1.250.000",
    actual_treatments: "1.000.000",
    forecast2: "1.250.000",
  },
]
