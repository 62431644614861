const capitalizeFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const capitalizeEachWord = (str: string) => {
  return str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
}

const formatRupiah = (num: number): string => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

const removeDots = (text: string | undefined) => {
  return text ? text?.replaceAll('.', '') : ''
}

export {capitalizeFirst, formatRupiah, removeDots, capitalizeEachWord}
