import {Modal, Spinner} from 'react-bootstrap'
import {FC, useEffect, useState} from 'react'
import {useSelectTreatmentModal} from '../core/_ModalProvider'
import {FormattedNumber, IntlProvider} from 'react-intl'
import clsx from 'clsx'
import {KTIcon} from '@/_metronic/helpers'
import {initialFilterProductVariantMobile, ProductVariantMobile} from '../core/_models'
import {getProductPrice, getProductTreatments, getProductVariantMobile} from '../core/_requests'

type Props = {
  prevData: Array<ProductVariantMobile>
  onSave: (treatments: Array<ProductVariantMobile>) => void
  outlet_id: string
  ids?: string
}

const SelectTreatmentModal: FC<Props> = ({onSave, prevData, outlet_id, ids}) => {
  const {show, closeModal} = useSelectTreatmentModal()

  const [keyword, setKeyword] = useState<string>('')
  const [isLoading, setLoading] = useState<boolean>(false)
  const [selectedTreatment, setSelectedTreatment] = useState<Array<ProductVariantMobile>>([])
  const [treatments, setTreatments] = useState<Array<ProductVariantMobile>>([])

  useEffect(() => {
    if (prevData && prevData?.length) setSelectedTreatment(prevData)
  }, [prevData])

  const getProductList = async (limit = 10) => {
    if (!outlet_id) return

    if (!isLoading) {
      setLoading(true)
    }

    try {
      const res = await getProductVariantMobile({
        ...initialFilterProductVariantMobile,
        filter: {...initialFilterProductVariantMobile.filter, set_outlet_id: true, outlet_id},
        limit,
      })
      const total = res?.response?.total_data || 10
      if (total > limit) {
        getProductList(total)
        return
      } else {
        setLoading(false)
      }

      setTreatments(res.response?.data || [])
    } catch (error) {}
  }

  useEffect(() => {
    if (outlet_id) getProductList()
  }, [outlet_id])

  const selectTreatment = (x: ProductVariantMobile) => () => {
    const copyTreatments = selectedTreatment.slice(0) as Array<any>
    let newTreatment: Array<any> = []
    if (copyTreatments.includes(x)) {
      newTreatment = copyTreatments.filter((v) => v.sku !== x.sku)
    } else {
      copyTreatments.push({
        ...x,
        product: {
          ...x.product,
          varian_name: `${x?.variant?.[0]?.type} ${x?.variant?.[0]?.variant}`,
        },
      })
      newTreatment = copyTreatments
    }

    setSelectedTreatment(newTreatment)
  }

  const filterTreatment = () => {
    if (!keyword.length) {
      return treatments
    }

    return treatments.filter(
      (x) =>
        x.sku?.toLowerCase().includes(keyword?.toLowerCase()) ||
        x.product?.name?.toLowerCase().includes(keyword?.toLowerCase())
    )
  }

  const isSelectTreatmentValid = () => {
    let isValid = true
    if (!selectedTreatment?.length) {
      return false
    }

    // selectedTreatment?.forEach((v) => {
    //   if (!v.qty) {
    //     isValid = false
    //   } else if (v.qty && v.qty === 0) {
    //     isValid = false
    //   }
    // })

    return isValid
  }

  const onChangeTreatmentQty = (isIncrement = true, sku) => {
    const index = selectedTreatment.findIndex((x) => x.sku === sku)
    const copySelected = selectedTreatment.slice(0)
    if (isIncrement) {
      copySelected[index].qty = (copySelected[index].qty || 0) + 1
    } else {
      if ((selectedTreatment[index].qty || 0) > 0) {
        copySelected[index].qty = (copySelected[index].qty || 0) - 1
      }
    }

    setSelectedTreatment(copySelected)
  }

  return (
    <Modal
      show={show}
      role='dialog'
      onHide={closeModal}
      className='modal fade'
      dialogClassName='h-200px'
      id={`modal-dialog-select-treatment${ids ? `-${ids}` : ''}`}
    >
      <div className='modal-content'>
        <div className='modal-body'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='text-center'>Treatment List</h3>
            </div>
            <div className='col-12'>
              <div className='d-flex align-items-center position-relative w-100 border rounded mt-3'>
                <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                <input
                  type='text'
                  id='kt_filter_search'
                  className='form-control form-control-white form-control-sm form-control-flush w-100 ps-10'
                  placeholder='Search'
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </div>
            </div>
            <div className='col-12'>
              <div className='mh-250px d-flex flex-column overflow-auto mt-5'>
                {isLoading ? (
                  <div className='d-flex justify-content-center'>
                    <Spinner
                      animation='border'
                      color='white'
                      style={{width: 50, height: 50, paddingTop: 5, paddingBottom: 5}}
                    />
                  </div>
                ) : (
                  filterTreatment().map((x) => {
                    const isSelected = selectedTreatment.find((v) => {
                      return v.sku === x.sku
                    })
                    return (
                      <div
                        key={x.sku}
                        className='d-flex flex-row gap-3 justify-content-between align-items-center border-bottom py-3 pe-3 cursor-pointer'
                        onClick={selectTreatment(x)}
                      >
                        <div className='d-flex flex-column' style={{flex: 1}}>
                          <span className='text-gray-800 fw-bold text-hover-primary mb-1'>
                            {`${x.product?.name} - ${x.variant?.[0]?.variant}`}
                          </span>
                          <span>
                            <IntlProvider locale='id'>
                              Rp.{' '}
                              <FormattedNumber
                                value={x.price?.[0]?.sell_price || 0}
                                currency='IDR'
                              />
                            </IntlProvider>
                            {/* <span className='mx-3'>·</span>
                        <span>{x.} menit</span> */}
                          </span>
                        </div>
                        {/* plus-circle-fill dash-circle-fill */}

                        {isSelected && (
                          <>
                            <div className='d-flex flex-row gap-3'>
                              {/* <i
                              className='bi bi-plus-circle-fill fs-1'
                              onClick={(event) => {
                                event?.stopPropagation()
                                onChangeTreatmentQty(true, x.product_variant_sku)
                              }}
                            ></i>
                            <span>{isSelected?.qty || '0'}</span>
                            <i
                              className='bi bi-dash-circle-fill fs-1'
                              onClick={(event) => {
                                event?.stopPropagation()
                                onChangeTreatmentQty(false, x.product_variant_sku)
                              }}
                            ></i> */}
                            </div>
                            <i className='bi bi-check-circle-fill text-success fs-1' />
                          </>
                        )}
                      </div>
                    )
                  })
                )}
              </div>
              {!isLoading && (
                <button
                  className={clsx('btn w-100 mt-3', {
                    'btn-secondary': !isSelectTreatmentValid(),
                    'btn-primary': isSelectTreatmentValid(),
                  })}
                  disabled={selectedTreatment?.length === 0}
                  onClick={() =>
                    onSave(
                      selectedTreatment.map((x) => {
                        if (x.qty) {
                          return x
                        }

                        return {...x, qty: 1}
                      })
                    )
                  }
                >
                  Simpan
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {SelectTreatmentModal}
