import {MenuComponent} from '@/_metronic/assets/ts/components'
import {DropdownSelect} from '@/_metronic/partials/content/dropdown-select/DropdownSelect'
import PropagateWrapper from '@/_metronic/partials/content/propagate/PropagateWrapper'
import {useEffect, useState} from 'react'
import {useFilterReportTrendAnalysis} from '../../core/FilterReportTrendAnalysisProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {initialFilter} from '../../core/_models'
import {FilterDropdownBrand} from './FilterDropdownBrand'
import {FilterDropdownCity} from './FilterDropdownCity'
import {FilterDropdownOutlet} from './FilterDropdownOutlet'
import {FilterDropdownRegion} from './FilterDropdownRegion'

type Props = {
  closeModal: () => void
}

const optionsArea = [
  {value: 'WIB', label: 'WIB'},
  {value: 'WITA', label: 'WITA'},
  {value: 'WIT', label: 'WIT'},
]

const optionsMonth = [
  {value: '1', label: 'January'},
  {value: '2', label: 'February'},
  {value: '3', label: 'March'},
  {value: '4', label: 'April'},
  {value: '5', label: 'May'},
  {value: '6', label: 'June'},
  {value: '7', label: 'July'},
  {value: '8', label: 'August'},
  {value: '9', label: 'September'},
  {value: '10', label: 'October'},
  {value: '11', label: 'November'},
  {value: '12', label: 'December'},
]

const FilterReportTrendAnalysisForm = ({closeModal}) => {
  const {updateFilterReportTrendAnalysisFormValue, filterReportTrendAnalysisFormValue} =
    useFilterReportTrendAnalysis()
  const {updateState} = useQueryRequest()
  const [filterValue, setFilterValue] = useState(initialFilter)
  const {isLoading} = useQueryResponse()
  const [selectedArea, setSelectedArea] = useState<any>([])
  const [selectedMonth, setSelectedMonth] = useState<any>([])
  const [selectedOutlet, setSelectedOutlet] = useState<any>([])
  const [selectedRegion, setSelectedRegion] = useState<any>([])
  const [selectedCity, setSelectedCity] = useState<any>([])
  const [selectedBrand, setSelectedBrand] = useState<any>([])
  const [selectedSubBrand, setSelectedSubBrand] = useState<any>([])
  const [optionsYear, setOptionsYear] = useState<any>(null)
  const [selectedYear, setSelectedYear] = useState<any>(null)
  const currentDate = new Date()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  useEffect(() => {
    const currentYear = new Date().getFullYear()
    const years = Array.from({length: 5}, (_, index) => currentYear - 4 + index)
    setOptionsYear(years?.map((x) => ({label: x, value: x})))

    if (filterReportTrendAnalysisFormValue) {
      setSelectedArea(filterReportTrendAnalysisFormValue.area)
      setSelectedMonth(filterReportTrendAnalysisFormValue.month)
      setSelectedOutlet(filterReportTrendAnalysisFormValue.outlet)
      setSelectedBrand(filterReportTrendAnalysisFormValue.brand)
      setSelectedSubBrand(filterReportTrendAnalysisFormValue.sub_brand)
      setSelectedYear(filterReportTrendAnalysisFormValue.year)
      setSelectedRegion(filterReportTrendAnalysisFormValue.region)
      setSelectedCity(filterReportTrendAnalysisFormValue.city)

      let monthList: string[] = []
      if (filterReportTrendAnalysisFormValue.month) {
        filterReportTrendAnalysisFormValue.month.forEach((element) => {
          monthList.push(element.value)
        })
      }
      let areaList: string[] = []
      if (filterReportTrendAnalysisFormValue.area) {
        filterReportTrendAnalysisFormValue.area.forEach((element) => {
          areaList.push(element.value)
        })
      }
      let outletList: string[] = []
      if (filterReportTrendAnalysisFormValue.outlet) {
        filterReportTrendAnalysisFormValue.outlet.forEach((element) => {
          outletList.push(element.value)
        })
      }
      let brandList: string[] = []
      if (filterReportTrendAnalysisFormValue.brand) {
        filterReportTrendAnalysisFormValue.brand.forEach((element) => {
          brandList.push(element.value)
        })
      }
      let subBrandList: string[] = []
      if (filterReportTrendAnalysisFormValue.sub_brand) {
        filterReportTrendAnalysisFormValue.sub_brand.forEach((element) => {
          subBrandList.push(element.value)
        })
      }
      let regionList: string[] = []
      if (filterReportTrendAnalysisFormValue.region) {
        filterReportTrendAnalysisFormValue.region.forEach((element) => {
          regionList.push(element.value)
        })
      }
      let cityList: string[] = []
      if (filterReportTrendAnalysisFormValue.city) {
        filterReportTrendAnalysisFormValue.city.forEach((element) => {
          cityList.push(element.value)
        })
      }

      setFilterValue({
        ...filterValue,
        set_area: Boolean(filterReportTrendAnalysisFormValue.area),
        area: areaList,
        set_outlet_id: Boolean(filterReportTrendAnalysisFormValue.outlet),
        outlet_id: outletList,
        set_brand_id: Boolean(filterReportTrendAnalysisFormValue.brand),
        brand_id: brandList,
        set_sub_brand_id: Boolean(filterReportTrendAnalysisFormValue.sub_brand),
        sub_brand_id: subBrandList,
        set_region_id: Boolean(filterReportTrendAnalysisFormValue.region),
        region_id: regionList,
        set_city_id: Boolean(filterReportTrendAnalysisFormValue.city),
        city_id: cityList,
        month: monthList,
        year: filterReportTrendAnalysisFormValue.year
          ? filterReportTrendAnalysisFormValue.year.value.toString()
          : currentDate.getFullYear().toString(),
      })
    } else {
      setSelectedYear({value: currentDate.getFullYear(), label: currentDate.getFullYear()})
      setFilterValue({
        ...filterValue,
        year: currentDate.getFullYear().toString(),
      })
      updateFilterReportTrendAnalysisFormValue({
        ...filterReportTrendAnalysisFormValue,
        year: {value: currentDate.getFullYear(), label: currentDate.getFullYear()},
      })
    }
  }, [])

  const resetData = () => {
    setSelectedOutlet([])
    setSelectedYear({value: currentDate.getFullYear(), label: currentDate.getFullYear()})
    setSelectedArea([])
    setSelectedBrand([])
    setSelectedSubBrand([])
    setSelectedRegion([])
    setSelectedCity([])
    setSelectedMonth(optionsMonth)
    setFilterValue({
      ...filterValue,
      year: currentDate.getFullYear().toString(),
    })
    updateFilterReportTrendAnalysisFormValue(null)
    updateFilterReportTrendAnalysisFormValue({
      ...filterReportTrendAnalysisFormValue,
      year: {value: currentDate.getFullYear(), label: currentDate.getFullYear()},
    })
    updateState(initialFilter)
    closeModal()
  }

  const filterData = () => {
    filterValue.set_brand_id = filterValue.brand_id && filterValue.brand_id.length > 0
    filterValue.set_sub_brand_id = filterValue.sub_brand_id && filterValue.sub_brand_id.length > 0
    filterValue.set_region_id = filterValue.region_id && filterValue.region_id.length > 0
    filterValue.set_city_id = filterValue.city_id && filterValue.city_id.length > 0
    filterValue.set_area = filterValue.area && filterValue.area.length > 0
    filterValue.set_outlet_id = filterValue.outlet_id && filterValue.outlet_id.length > 0
    updateState(filterValue)
    closeModal()
  }

  const onChangeSelected = (name: string) => (value) => {
    if (name === 'area') {
      setSelectedArea(value)

      let areaList: string[] = []
      if (value) {
        value.forEach((element) => {
          areaList.push(element.value)
        })
      }
      setFilterValue({
        ...filterValue,
        set_area: Boolean(areaList),
        area: areaList,
      })
      updateFilterReportTrendAnalysisFormValue({
        ...filterReportTrendAnalysisFormValue,
        area: value,
      })
    } else if (name === 'month') {
      setSelectedMonth(value)

      let monthList: string[] = []
      if (value) {
        value.forEach((element) => {
          monthList.push(element.value)
        })
      }

      setFilterValue({
        ...filterValue,
        month: monthList,
      })
      updateFilterReportTrendAnalysisFormValue({
        ...filterReportTrendAnalysisFormValue,
        month: value,
      })
    } else if (name === 'year') {
      console.log(value)
      if (value) {
        setSelectedYear(value)
      } else {
        setSelectedYear({value: currentDate.getFullYear(), label: currentDate.getFullYear()})
      }
      setFilterValue({
        ...filterValue,
        year: value && value.value.toString(),
      })
      updateFilterReportTrendAnalysisFormValue({
        ...filterReportTrendAnalysisFormValue,
        year: value ? value : {value: currentDate.getFullYear(), label: currentDate.getFullYear()},
      })
    }
  }

  return (
    <>
      <div className='px-7 py-5' data-kt-user-table-filter='form'>
        <PropagateWrapper>
          <div className='row'>
            <div className='col-6'>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Brand:</label>
                <FilterDropdownBrand
                  currentValue={selectedBrand || null}
                  onValueChange={(value) => {
                    setSelectedBrand(value)
                    let brandList: string[] = []
                    if (value) {
                      value.forEach((element) => {
                        brandList.push(element.value)
                      })
                    }
                    setFilterValue({
                      ...filterValue,
                      set_brand_id: Boolean(selectedBrand),
                      brand_id: brandList,
                    })
                    updateFilterReportTrendAnalysisFormValue({
                      ...filterReportTrendAnalysisFormValue,
                      brand: selectedBrand,
                    })
                  }}
                />
              </div>
            </div>
            <div className='col-6'>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Sub Brand:</label>
                <FilterDropdownBrand
                  currentValue={selectedSubBrand || null}
                  onValueChange={(value) => {
                    setSelectedSubBrand(value)
                    let subBrandList: string[] = []
                    if (value) {
                      value.forEach((element) => {
                        subBrandList.push(element.value)
                      })
                    }
                    setFilterValue({
                      ...filterValue,
                      set_sub_brand_id: Boolean(selectedSubBrand),
                      sub_brand_id: subBrandList,
                    })
                    updateFilterReportTrendAnalysisFormValue({
                      ...filterReportTrendAnalysisFormValue,
                      sub_brand: selectedSubBrand,
                    })
                  }}
                />
              </div>
            </div>
            <div className='col-6'>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Region:</label>
                <FilterDropdownRegion
                  currentValue={selectedRegion || null}
                  onValueChange={(value) => {
                    setSelectedRegion(value)
                    let regionList: string[] = []
                    if (value) {
                      value.forEach((element) => {
                        regionList.push(element.value)
                      })
                    }
                    setFilterValue({
                      ...filterValue,
                      set_region_id: Boolean(selectedRegion),
                      region_id: regionList,
                    })
                    updateFilterReportTrendAnalysisFormValue({
                      ...filterReportTrendAnalysisFormValue,
                      region: selectedRegion,
                    })
                  }}
                />
              </div>
            </div>
            <div className='col-6'>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>City:</label>
                <FilterDropdownCity
                  currentValue={selectedCity || null}
                  onValueChange={(value) => {
                    setSelectedCity(value)
                    let cityList: string[] = []
                    if (value) {
                      value.forEach((element) => {
                        cityList.push(element.value)
                      })
                    }
                    setFilterValue({
                      ...filterValue,
                      set_city_id: Boolean(selectedCity),
                      city_id: cityList,
                    })
                    updateFilterReportTrendAnalysisFormValue({
                      ...filterReportTrendAnalysisFormValue,
                      city: selectedCity,
                    })
                  }}
                />
              </div>
            </div>
            <div className='col-6'>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Area:</label>
                <DropdownSelect
                  options={optionsArea}
                  value={selectedArea}
                  onChange={onChangeSelected('area')}
                  placeholder='All Area'
                  isMulti
                  isClearable
                />
              </div>
            </div>

            <div className='col-6'>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Outlet:</label>
                <FilterDropdownOutlet
                  placeholder='All Outlet'
                  currentValue={selectedOutlet || null}
                  onValueChange={(value) => {
                    setSelectedOutlet(value)
                    let outletList: string[] = []
                    if (value) {
                      value.forEach((element) => {
                        outletList.push(element.value)
                      })
                    }
                    setFilterValue({
                      ...filterValue,
                      set_outlet_id: Boolean(selectedOutlet),
                      outlet_id: outletList,
                    })
                    updateFilterReportTrendAnalysisFormValue({
                      ...filterReportTrendAnalysisFormValue,
                      outlet: selectedOutlet,
                    })
                  }}
                  isDisabled={false}
                />
              </div>
            </div>
            <div className='col-6'>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Month:</label>
                <DropdownSelect
                  options={optionsMonth}
                  isMulti
                  isClearable
                  value={selectedMonth}
                  onChange={onChangeSelected('month')}
                  placeholder='All Month'
                />
              </div>
            </div>
            <div className='col-6'>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Year:</label>
                <DropdownSelect
                  options={optionsYear}
                  placeholder='Select Year'
                  value={selectedYear}
                  onChange={onChangeSelected('year')}
                  isClearable
                />
              </div>
            </div>
          </div>
        </PropagateWrapper>
        <div className='d-flex justify-content-end'>
          <button
            type='button'
            // disabled={isLoading}
            onClick={resetData}
            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
            data-kt-menu-dismiss='true'
            data-kt-user-table-filter='reset'
          >
            Reset
          </button>
          <button
            // disabled={isLoading}
            type='button'
            onClick={filterData}
            className='btn btn-primary fw-bold px-6'
            data-kt-menu-dismiss='true'
            data-kt-user-table-filter='filter'
          >
            Apply
          </button>
        </div>
      </div>
    </>
  )
}

export {FilterReportTrendAnalysisForm}
