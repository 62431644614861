import { BarChart } from '@/_metronic/partials/content/chart/BarChart'
import { useEffect, useState } from 'react'
import { AchievementProgress } from './components/AchievementProgress'
import { useQueryResponseData } from './core/QueryResponseProvider'

const ForecastOverview = () => {
  const data = useQueryResponseData()
  const [arrayOfSeriesRecentStatisticsSales, setArrayOfSeriesRecentStatisticsSales] = useState<
    number[]
  >([])
  const [arrayOfSeriesRecentStatisticsSalesTarget, setArrayOfSeriesRecentStatisticsSalesTarget] =
    useState<number[]>([])
  const [arrayOfLabelsRecentStatistics, setArrayOfLabelsRecentStatistics] = useState<string[]>([])
  const [arrayOfSeriesRecentStatisticsTreatment, setArrayOfSeriesRecentStatisticsTreatment] =
    useState<number[]>([])
  const [
    arrayOfSeriesRecentStatisticsTreatmentTarget,
    setArrayOfSeriesRecentStatisticsTreatmentTarget,
  ] = useState<number[]>([])

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
  }

  useEffect(() => {
    if (data) {
      setArrayOfSeriesRecentStatisticsSales([])
      setArrayOfSeriesRecentStatisticsSalesTarget([])
      setArrayOfLabelsRecentStatistics([])
      setArrayOfSeriesRecentStatisticsTreatment([])
      setArrayOfSeriesRecentStatisticsTreatmentTarget([])
      if (data?.monthly_sales_vs_budget) {
        const arrayOfSeriesSalesString = data?.monthly_sales_vs_budget?.map((obj) =>
          obj.actual_sales ? obj.actual_sales : 0
        )
        setArrayOfSeriesRecentStatisticsSales(arrayOfSeriesSalesString)
        const arrayOfSeriesSalesTargetString = data?.monthly_sales_vs_budget?.map((obj) =>
          obj.sales_target ? obj.sales_target : 0
        )
        setArrayOfSeriesRecentStatisticsSalesTarget(arrayOfSeriesSalesTargetString)
        const arrayOfSeriesTreatmetnString = data?.monthly_sales_vs_budget?.map((obj) =>
          obj.actual_treatment ? obj.actual_treatment : 0
        )
        setArrayOfSeriesRecentStatisticsTreatment(arrayOfSeriesTreatmetnString)
        const arrayOfSeriesTreatmetnTargetString = data?.monthly_sales_vs_budget?.map((obj) =>
          obj.treatment_target ? obj.treatment_target : 0
        )
        setArrayOfSeriesRecentStatisticsTreatmentTarget(arrayOfSeriesTreatmetnTargetString)

        const arrayOfLabelsString = data?.monthly_sales_vs_budget?.map((obj, index) => {
          return obj.month_value ? obj.month_value : ''
        })
        setArrayOfLabelsRecentStatistics(arrayOfLabelsString)
      }
    }
  }, [data])

  return (
    <>
      {/* <div className='mt-8'>
        <h4>Summary Information</h4>
        <div className='d-flex flex-row gap-4 mt-8'>
          <div className='flex-fill'>
            <AverageCard
              className='card-xl mb-xl-8 shadow-wit'
              color='white'
              title='Average Sales'
              subtitle='Sales Reports'
              description='Rp. 145.000.000'
              descriptionColor='info'
            />
          </div>
          <div className='flex-fill'>
            <AverageCard
              className='card-xl mb-xl-8 shadow-wit'
              color='white'
              title='Average Treatments'
              subtitle='Sales Reports'
              description='Rp. 425.000'
              descriptionColor='warning'
            />
          </div>
          <div className='flex-fill'>
            <AverageCard
              className='card-xl mb-xl-8 shadow-wit'
              color='white'
              title='Average Transactions'
              subtitle='Sales Reports'
              description='15'
              descriptionColor='primary'
            />
          </div>
        </div>
      </div> */}

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-6 mb-5'>
          <AchievementProgress
            className='primary shadow-wit'
            chartColor='primary'
            chartHeight='250'
            title='Sales Achievement'
            subTitle='Compared to your target'
            bar={
              data &&
              data.sales_achievement &&
              data?.sales_achievement?.completion_rate &&
              data?.sales_achievement?.completion_rate?.origin
            }
            actual={
              data && data.sales_achievement && data?.sales_achievement?.actual_sales
                ? 'Rp. ' + numberWithCommas(data?.sales_achievement?.actual_sales)
                : 'Rp. 0'
            }
            target={
              data && data.sales_achievement && data?.sales_achievement?.sales_target
                ? 'Rp. ' + numberWithCommas(data?.sales_achievement?.sales_target)
                : 'Rp. 0'
            }
          />
        </div>

        <div className='col-xl-6'>
          <AchievementProgress
            className='success shadow-wit'
            chartColor='success'
            chartHeight='250'
            title='Treatments Achievement'
            subTitle='Compared to your target'
            bar={
              data &&
              data.treatment_achievement &&
              data?.treatment_achievement?.completion_rate &&
              data?.treatment_achievement?.completion_rate?.origin
            }
            actual={
              data && data.treatment_achievement && data?.treatment_achievement?.actual_treatment
                ? numberWithCommas(data?.treatment_achievement?.actual_treatment)
                : '0'
            }
            target={
              data && data.treatment_achievement && data?.treatment_achievement?.treatment_target
                ? numberWithCommas(data?.treatment_achievement?.treatment_target)
                : '0'
            }
          />
        </div>
      </div>

      <div className='col-xl-12 mt-10'>
        <BarChart
          className='shadow-wit'
          color='primary'
          title='Actual vs Target (Sales)'
          subtitle=''
          height='260px'
          series={[
            {
              name: 'Actual',
              data: arrayOfSeriesRecentStatisticsSales,
            },
            {
              name: 'Target',
              data: arrayOfSeriesRecentStatisticsSalesTarget,
            },
          ]}
          categories={arrayOfLabelsRecentStatistics}
          total={0}
          isTotal={false}
        />
      </div>

      <div className='col-xl-12 mt-10 mb-3'>
        <BarChart
          className='shadow-wit'
          color='success'
          title='Actual vs Target (Treatment)'
          subtitle=''
          height='260px'
          series={[
            {
              name: 'Actual',
              data: arrayOfSeriesRecentStatisticsTreatment,
            },
            {
              name: 'Target',
              data: arrayOfSeriesRecentStatisticsTreatmentTarget,
            },
          ]}
          categories={arrayOfLabelsRecentStatistics}
          total={0}
          isCurrency={false}
          isTotal={false}
        />
      </div>
    </>
  )
}

export default ForecastOverview
