import {toAbsoluteUrl} from '@/_metronic/helpers'
import {InputIconGroup} from '@/_metronic/partials/content/input-group/InputIconGroup'
import {FormikValues, useFormik} from 'formik'
import moment from 'moment'
import {FC, useEffect, useState} from 'react'
import {Spinner} from 'react-bootstrap'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import SVG from 'react-inlinesvg'
import {FormattedNumber, IntlProvider} from 'react-intl'
import * as Yup from 'yup'
import {useBookingFormState} from '../core/FormStateProvider'
import {useSelectTreatmentModal} from '../core/_ModalProvider'
import {ProductPrice} from '../core/_models'
import {BookingSuccessModal} from './BookingSuccessModal'
import DropdownFacility from './DropdownFacility'
import {DropdownGender} from './DropdownGender'
import {SelectTreatmentModal} from './SelectTreatmentModal'

const guestFormSchemes = Yup.object().shape({
  name: Yup.string().required('Nama tidak boleh kosong'),
  phone_number: Yup.string().required('No HP tidak boleh kosong'),
  room_number: Yup.string().nullable(),
  room_type: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Jenis kamar tidak boleh kosong'),
  booking_date: Yup.string()
    .test('someTest', 'Tanggal booking tidak boleh sebelum sekarang', function (value, context) {
      const {booking_date} = context.parent
      if (moment(booking_date, 'DD MMMM yyyy').isBefore(moment(), 'days')) {
        return this.createError({message: 'Waktu booking harus lebih dari sekarang'})
      }

      return true
    })
    .required('Tanggal booking tidak boleh kosong'),
  gender: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Jenis kelamin tidak boleh kosong'),
  booking_time: Yup.string()
    .test('someTest', 'Jam booking tidak boleh sebelum sekarang', function (value, context) {
      const {booking_date} = context.parent
      if (moment(booking_date, 'DD MMMM yyyy').isSame(new Date(), 'day')) {
        if (moment(value, 'HH:mm').isBefore(new Date())) {
          return this.createError({message: 'Waktu booking harus lebih dari sekarang'})
        }
      }

      return true
    })
    .required('Jam booking tidak boleh kosong'),
})

const guestFormInitialValues = {
  name: '',
  phone_number: '',
  room_number: '',
  room_type: {label: 'Cabin', value: 'cabin'},
  booking_date: moment().format('DD MMMM yyyy'),
  booking_time: '',
  gender: null,
}

type Props = {
  outlet_id: string
  handleSubmit: (body: any) => void
}

const WalkInForm: FC<Props> = ({outlet_id, handleSubmit}) => {
  const {showModal, closeModal, show, showSuccess, showSuccessModal, closeSuccessModal} =
    useSelectTreatmentModal()
  const [treatments, setTreatments] = useState<Array<ProductPrice>>([])
  const [bookDate] = useState(null)
  const {isLoading, setIsLoading} = useBookingFormState()

  const onClickShowTreatment = () => {
    showModal()
  }

  useEffect(() => {
    if (!showSuccess) {
      handleReset(guestFormInitialValues)
      setTreatments([])
    }
  }, [showSuccess])

  const onSubmit = (values: FormikValues) => {
    const body = {
      outlet_guid: outlet_id,
      employee_guid: '',
      customer_guid: '',
      customer_name: values.name,
      customer_phone: values.phone_number,
      customer_gender: values.gender?.value,
      type_order: 'walk in',
      room_number: parseInt(values.room_number),
      type_facility: values.room_type?.value,
      booking_date: moment(values.booking_date, 'DD MMMM yyyy').format('yyyy-MM-DD'),
      booking_time: moment(values.booking_time, 'HH:mm').format('HH:mm:ss'),
      notes: '',
      product: treatments?.map((x) => ({
        sku: x.sku,
        qty: x.qty,
      })),
    }
    setIsLoading(true)
    handleSubmit(body)
  }

  useEffect(() => {
    if (!showSuccessModal) {
      handleReset(guestFormInitialValues)
    }
  }, [showSuccessModal])

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    handleReset,
    setFieldValue,
    setFieldTouched,
    handleSubmit: handleFormSubmit,
    isValid,
  } = useFormik({
    initialValues: guestFormInitialValues,
    validationSchema: guestFormSchemes,
    onSubmit,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  })

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <div className='mb-5'>
              <InputIconGroup
                label='Nama'
                placeholder='John doe'
                isValid={Boolean(values.name)}
                errorMessage={
                  !Boolean(values.name) && touched?.name ? errors?.name?.toString() : ''
                }
                value={values.name}
                onChange={handleChange('name')}
                onBlur={handleBlur('name')}
                icon='/media/svg/orders/user.svg'
                disabled={!outlet_id}
              />
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='mb-5'>
              <InputIconGroup
                label='No. HP'
                placeholder='628000xxxxxx'
                isValid={Boolean(values.phone_number)}
                errorMessage={
                  !Boolean(values.phone_number) && touched?.phone_number
                    ? errors?.phone_number?.toString()
                    : ''
                }
                value={values.phone_number}
                onChange={handleChange('phone_number')}
                onBlur={handleBlur('phone_number')}
                icon='/media/svg/orders/phone.svg'
                disabled={!outlet_id}
              />
            </div>
          </div>
          <div className='col-6'>
            <div className='mb-5'>
              <label htmlFor='room_type' className='required form-label'>
                Jenis Kamar
              </label>
              <DropdownFacility
                value={values.room_type}
                onBlur={handleBlur('room_type')}
                isDisabled={true}
                onChange={(newValue) => {
                  setFieldValue('room_type', newValue)
                  setTimeout(() => {
                    setFieldTouched('room_type', !Boolean(newValue))
                  })
                }}
                options={[
                  {
                    label: 'Cabin',
                    value: 'cabin',
                  },
                  {
                    label: 'In Room',
                    value: 'in-room',
                  },
                ]}
              />
              {touched.room_type && errors.room_type && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.room_type?.toString()}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='col-6'>
            <div className='mb-5'>
              <label htmlFor='room_type' className='required form-label'>
                Jenis Kelamin
              </label>
              <DropdownGender
                currentValue={values.gender}
                isDisabled={!outlet_id}
                onChange={(newValue) => {
                  setFieldValue('gender', newValue)
                  setTimeout(() => {
                    setFieldTouched('gender', !Boolean(newValue))
                  })
                }}
                onBlur={handleBlur('gender')}
              />
              {touched.room_type && errors.room_type && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.room_type?.toString()}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='mb-5'>
              <label htmlFor='book_date' className='required form-label'>
                Tanggal Booking
              </label>
              <DateRangePicker
                initialSettings={{
                  singleDatePicker: true,
                  showDropdowns: true,
                  parentEl: '#booking-order-date',
                }}
                onCallback={(start) => {
                  setFieldValue('booking_date', start.format('DD MMMM yyyy'))
                }}
              >
                <div
                  className='d-flex align-items-center position-relative w-100'
                  id='booking-order-date'
                >
                  <InputIconGroup
                    type='text'
                    id='book_date'
                    // className='form-control form-control-solid w-100 pe-10'
                    placeholder={moment().format('DD MMMM yyyy')}
                    value={values.booking_date ? values.booking_date : ''}
                    readOnly
                    icon='/media/svg/orders/calendar.svg'
                    disabled={!outlet_id}
                  />
                </div>
              </DateRangePicker>
              {touched.booking_date && errors.booking_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.booking_date}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='mb-5'>
              <label htmlFor='book_date' className='required form-label'>
                Jam Booking
              </label>

              <div className='d-flex align-items-center position-relative w-100'>
                <InputIconGroup
                  type='time'
                  id='book_date'
                  // className='form-control form-control-solid w-100'
                  placeholder={moment().format('HH:mm')}
                  value={values.booking_time}
                  disabled={!Boolean(values.booking_date) || !outlet_id}
                  // min={
                  //   moment(values?.booking_date, 'DD MMMM yyyy').isSame(values?.booking_date, 'day')
                  //     ? moment().format('yyyy-MM-dd[T]HH:mm')
                  //     : undefined
                  // }
                  onChange={handleChange('booking_time')}
                  onBlur={handleBlur('booking_time')}
                  icon='/media/svg/orders/clock.svg'
                />
              </div>
              {touched.booking_time && errors.booking_time && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.booking_time}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='col-12'>
            <div className='mb-5 d-flex flex-column'>
              <div className='d-flex justify-content-between align-items-center mb-3'>
                <label className='required form-label'>Treatment</label>
                <button
                  className='btn btn-icon bg-info btn-sm text-white'
                  onClick={onClickShowTreatment}
                  disabled={!outlet_id}
                >
                  <i className='bi-plus fs-3'></i>
                </button>
              </div>
              {treatments?.length === 0 && (
                <div className='btn btn-outline btn-outline-dashed btn-outline-default p-7 border-info d-flex flex-row justify-content-center'>
                  <button
                    className='btn d-flex flex-row align-items-center gap-2'
                    onClick={onClickShowTreatment}
                    type='button'
                    disabled={!outlet_id}
                  >
                    <div className='btn btn-outline btn-outline btn-outline-default p-3 border-muted d-flex flex-row justify-content-center'>
                      <SVG className='fs-3' src={toAbsoluteUrl('/media/svg/orders/magicpen.svg')} />
                    </div>
                    <span className='ms-3 text-info'>Pilih Treatment</span>
                  </button>
                </div>
              )}
              {treatments?.length > 0 && (
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-150px'>Treatment</th>
                      <th className='min-w-140px'>Quantity</th>
                      <th className='min-w-120px'>Harga</th>
                      <th className='min-w-120px'>Aksi</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {treatments.map((treat) => (
                      <tr key={treat.sku}>
                        <td>{treat?.product?.name}</td>
                        <td>
                          <div className='d-flex flex-row gap-3 align-items-center'>
                            <i
                              className='bi bi-plus-circle-fill fs-1'
                              style={{cursor: 'pointer'}}
                              onClick={(event) => {
                                if (!outlet_id) return
                                const qty = (treat?.qty || 0) + 1
                                const treatment = treatments.map((x) => {
                                  if (x.sku === treat.sku) {
                                    return {...x, qty}
                                  }

                                  return x
                                })
                                setTreatments(treatment)
                              }}
                            ></i>
                            <span className='fs-3'>{treat?.qty || '0'}</span>
                            <i
                              className='bi bi-dash-circle-fill fs-1'
                              style={{cursor: 'pointer'}}
                              onClick={(event) => {
                                if (!outlet_id) return
                                const qty = (treat?.qty || 0) - 1
                                const treatment = treatments.map((x) => {
                                  if (x.sku === treat.sku) {
                                    return {...x, qty}
                                  }

                                  return x
                                })
                                setTreatments(treatment)
                              }}
                            ></i>
                          </div>
                        </td>
                        {/* <td>{treat.duration} menit</td> */}
                        <td>
                          <IntlProvider locale='id'>
                            Rp.{' '}
                            <FormattedNumber
                              value={(treat.price?.[0]?.sell_price || 0) * (treat.qty || 1)}
                            />
                          </IntlProvider>
                        </td>
                        <td>
                          <i
                            className='bi bi-x-lg fs-3 text-danger cursor-pointer'
                            onClick={() => {
                              if (!outlet_id) return
                              setTreatments((prev) => prev.filter((x) => x.sku !== treat.sku))
                            }}
                          ></i>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={2} className='text-end'>
                        Total
                      </td>
                      <td>
                        <IntlProvider locale='id'>
                          Rp.{' '}
                          <FormattedNumber
                            value={
                              treatments.reduce(
                                (partialSum, a) =>
                                  partialSum + (a.price?.[0]?.sell_price || 0) * (a.qty || 1),
                                0
                              ) || 0
                            }
                          />
                        </IntlProvider>
                      </td>
                    </tr>
                  </tbody>
                  {/* end::Table body */}
                </table>
              )}
            </div>
          </div>
          <div className='col-12'>
            <button
              className='btn btn-md btn-info w-100'
              disabled={!isValid || !treatments.length || isLoading || !outlet_id}
            >
              {isLoading ? <Spinner color='white' size='sm' animation='border' /> : 'Book'}
            </button>
          </div>
        </div>
        <BookingSuccessModal
          onReserve={() => {
            handleReset(guestFormInitialValues)
            setTreatments([])
            closeSuccessModal()
          }}
        />
      </form>

      {show && (
        <SelectTreatmentModal
          outlet_id={outlet_id}
          ids='walkin'
          prevData={treatments}
          onSave={(data: Array<ProductPrice>) => {
            setTreatments(data)
            closeModal()
          }}
        />
      )}
    </>
  )
}

export default WalkInForm
