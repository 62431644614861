/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTCard } from '@/_metronic/helpers/components/KTCard'
import { FC, useState } from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import ForecastOverview from './ForecastOverview'
import { FilterReportSalesBudgetModal } from './components/filter/FilterReportSalesBudgetModal'
import { ReportSalesBudgetListHeader } from './components/filter/ReportSalesBudgetListHeader'
import {
  FilterReportSalesBudgetProvider,
  useFilterReportSalesBudget,
} from './core/FilterReportSalesBudgetProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { ForecastListWrapper } from './forecast-list/ForecastList'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Report',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Sales',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DashboardForecastPage: FC = () => {
  const [activeTab, setActiveTab] = useState<string>('overview')
  const {closeModal, showFilterReportSalesBudgetModal} = useFilterReportSalesBudget()

  return (
    <>
      <PageTitle breadcrumbs={[]}>Dashboard Sales vs Budget</PageTitle>
      {/* <div className="bg-white px-8">
        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
          <li className="nav-item mt-2">
            <a onClick={() => setActiveTab("overview")} className={`cursor-pointer nav-link text-active-primary ms-0 me-10 py-5 ${activeTab === 'overview' ? "active" : ""}`}>
              Overview
            </a>
          </li>
          <li className="nav-item mt-2">
            <a onClick={() => setActiveTab("detail")} className={`cursor-pointer nav-link text-active-primary ms-0 me-10 py-5 ${activeTab === 'detail' ? "active" : ""}`}>
              Detail
            </a>
          </li>
        </ul>
      </div> */}

      <KTCard className='py-4 shadow-wit-2 mb-10'>
        <ReportSalesBudgetListHeader />
      </KTCard>

      {activeTab === 'overview' ? <ForecastOverview /> : <ForecastListWrapper />}
      <FilterReportSalesBudgetModal
        show={showFilterReportSalesBudgetModal}
        closeModal={() => closeModal()}
      />
    </>
  )
}

const DashboardForecastWrapper: FC = () => {
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <FilterReportSalesBudgetProvider>
            <DashboardForecastPage />
          </FilterReportSalesBudgetProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export { DashboardForecastWrapper }

