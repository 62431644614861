import { KTIcon } from '@/_metronic/helpers'
import { FC, useState } from 'react'
import { useFilterReportSalesBudget } from '../../core/FilterReportSalesBudgetProvider'

type Props = {
  closeModal: () => void
}

const FilterReportSalesBudgetModalHeader: FC<Props> = ({closeModal}) => {
  const {updateFilterReportSalesBudgetFormValue, filterReportSalesBudgetFormValue} =
    useFilterReportSalesBudget()
  const [filterValueTemp, setFilterValueTemp] = useState(filterReportSalesBudgetFormValue)

  const cancelModal = () => {
    updateFilterReportSalesBudgetFormValue(filterValueTemp)
    closeModal()
  }

  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>Advanced Filter</h2>

      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={cancelModal}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
    </div>
  )
}

export { FilterReportSalesBudgetModalHeader }

