import { WithChildren } from '@/_metronic/helpers'
import { createContext, FC, useContext, useState } from 'react'

type FilterReportSalesBudgetContextProps = {
  showFilterReportSalesBudgetModal: boolean
  filterReportSalesBudgetFormValue: any
  updateFilterReportSalesBudgetFormValue: (formValue: any) => void
  handleShowModal: () => void
  closeModal: () => void
}

const filterReportSalesBudgetContextInitial: FilterReportSalesBudgetContextProps = {
  showFilterReportSalesBudgetModal: false,
  filterReportSalesBudgetFormValue: null,
  updateFilterReportSalesBudgetFormValue: () => {},
  handleShowModal: () => {},
  closeModal: () => {},
}

const FilterReportSalesBudgetContext = createContext<FilterReportSalesBudgetContextProps>(
  filterReportSalesBudgetContextInitial
)

const FilterReportSalesBudgetProvider: FC<WithChildren> = ({children}) => {
  const [showFilterReportSalesBudgetModal, setShowFilterReportSalesBudgetModal] =
    useState<boolean>(false)
  const [filterReportSalesBudgetFormValue, setFilterReportSalesBudgetFormValue] =
    useState<any>(null)

  const updateFilterReportSalesBudgetFormValue = (newValue) => {
    setFilterReportSalesBudgetFormValue(newValue)
  }

  const handleShowModal = () => {
    setShowFilterReportSalesBudgetModal(true)
  }

  const closeModal = () => {
    setShowFilterReportSalesBudgetModal(false)
  }

  return (
    <FilterReportSalesBudgetContext.Provider
      value={{
        showFilterReportSalesBudgetModal,
        filterReportSalesBudgetFormValue,
        updateFilterReportSalesBudgetFormValue,
        handleShowModal,
        closeModal,
      }}
    >
      {children}
    </FilterReportSalesBudgetContext.Provider>
  )
}

const useFilterReportSalesBudget = () => useContext(FilterReportSalesBudgetContext)

export { FilterReportSalesBudgetProvider, useFilterReportSalesBudget }

