import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react'
import {LayoutSplashScreen} from '@/_metronic/layout/core'
import {AuthModel, GenerateTokenRequest, ProfileModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {doLogout, generateToken, getIamUserAccess, getUserByToken} from './_requests'
import {groupMenusByLevelAndOrder, WithChildren} from '@/_metronic/helpers'
import {useLocation} from 'react-router-dom'
import {uniqBy} from 'lodash'
import {MenuAccess} from '../../user-management/user-management-edit/core/_model'

type AuthContextProps = {
  auth: AuthModel | undefined
  token: string | null
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: ProfileModel | undefined
  setCurrentUser: Dispatch<SetStateAction<ProfileModel | undefined>>
  menuAccess: Array<MenuAccess>
  saveMenuAccess: (accesses: Array<MenuAccess>) => void
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  token: authHelper.getToken(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  menuAccess: [],
  saveMenuAccess: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [token, setToken] = useState<string | null>(authHelper.getToken())
  const [currentUser, setCurrentUser] = useState<ProfileModel | undefined>()
  const [menuAccess, setMenuAccess] = useState<Array<MenuAccess>>([])
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    doLogout().catch((err) => console.error(err))
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  useEffect(() => {
    if (!token) {
      // generate token
      getToken()
    }
  }, [token])

  const getToken = async () => {
    const accessToken = await authHelper.fetchToken()
    setToken(accessToken)
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        token,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        menuAccess,
        saveMenuAccess: setMenuAccess,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {token, logout, setCurrentUser, saveMenuAccess} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const location = useLocation()

  const notForbiddenRoutes = useMemo(() => {
    return (
      !location?.pathname?.startsWith('/order') &&
      !location?.pathname?.startsWith('/rating/') &&
      !location?.pathname?.startsWith('/privacy-policy') &&
      !location?.pathname?.startsWith('/forgot-password/')
    )
  }, [location])

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestIam = async () => {
      let hasAccessToThisPage = false
      try {
        const {data} = await getIamUserAccess()
        const menuAccess = groupMenusByLevelAndOrder(data?.response?.data?.menu_access)
        saveMenuAccess(menuAccess)
        // const accessThisPage = menuAccess?.find((x) => x.url_path.includes(location.pathname))
        // if (accessThisPage) {
        //   const hasAccess = accessThisPage?.has_access
        //   if (hasAccess?.is_create || hasAccess?.is_update || hasAccess?.is_delete) {
        //     hasAccessToThisPage = true
        //   }
        // }

        // if (!hasAccessToThisPage) {
        //   window.location.href = '/404'
        // }
      } catch (error) {
        console.error(error)
      }
    }

    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(apiToken)
          if (data) {
            setCurrentUser(data?.response?.data)
            if (notForbiddenRoutes && location.pathname !== '/error/404') {
              requestIam()
            }
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (notForbiddenRoutes) {
      if (token) {
        requestUser(token)
      } else {
        logout()
        setShowSplashScreen(false)
      }
    } else {
      // didRequest.current = true
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
