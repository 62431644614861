import { BaseRequest } from '@/_metronic/helpers/crud-helper/base-request'
import { FilterPartnerOrganization, PartnerOrganizationQueryResponse, PartnerOrganization, PicPartnerOrganization } from './_models'
import {AxiosResponse} from 'axios'
import {axios} from '@/_metronic/helpers/network/Axios'
import { BaseResponse } from '@/_metronic/helpers/crud-helper/base-list-response'

const OUTET_API_URL = '/outlet-services'
const GET_PARTNER_ORGANIZER = '/partner-organization/list'
const GET_PARTNER_ORGANIZER_BY_ID_URL = '/partner-organization'

const getPartnerOrganizations = (
  body: BaseRequest<FilterPartnerOrganization>
): Promise<PartnerOrganizationQueryResponse> => {
  return axios
    .post(`${OUTET_API_URL}${GET_PARTNER_ORGANIZER}`, body)
    .then((d: AxiosResponse<PartnerOrganizationQueryResponse>) => d.data)
}

const getPartnerOrganizationById = (id: string | undefined): Promise<BaseResponse<PartnerOrganization>> => {
  return axios
    .get(`${OUTET_API_URL}${GET_PARTNER_ORGANIZER_BY_ID_URL}/detail/${id}`)
    .then((d: AxiosResponse<BaseResponse<PartnerOrganization>>) => d.data)
}

const createPartnerOrganization = (body: PartnerOrganization): Promise<BaseResponse<any>> => {
  return axios
    .post(`${OUTET_API_URL}${GET_PARTNER_ORGANIZER_BY_ID_URL}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const createPartnerOrganizationPic = (body: PicPartnerOrganization): Promise<BaseResponse<any>> => {
  return axios
    .post(`${OUTET_API_URL}${GET_PARTNER_ORGANIZER_BY_ID_URL}/pic`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const updatePartnerOrganization = (id: string, body: PartnerOrganization): Promise<BaseResponse<any>> => {
  return axios
    .put(`${OUTET_API_URL}${GET_PARTNER_ORGANIZER_BY_ID_URL}/${id}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const updatePartnerOrganizationPic = (id: string, body: PicPartnerOrganization): Promise<BaseResponse<any>> => {
  return axios
    .put(`${OUTET_API_URL}${GET_PARTNER_ORGANIZER_BY_ID_URL}/pic/${id}`, body)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

const deletePartnerOrganization = (id: string): Promise<BaseResponse<any>> => {
  return axios
    .delete(`${OUTET_API_URL}${GET_PARTNER_ORGANIZER_BY_ID_URL}/${id}`)
    .then((d: AxiosResponse<BaseResponse<any>>) => d.data)
}

export {getPartnerOrganizations, createPartnerOrganization, getPartnerOrganizationById, updatePartnerOrganization, deletePartnerOrganization, createPartnerOrganizationPic, updatePartnerOrganizationPic}