import {RESP} from '@/_metronic/helpers/network/network-consts'
import {FC, useEffect, useState} from 'react'
import SVG from 'react-inlinesvg'
import {Link, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Outlet} from '../outlet/core/_models'
import {getOutletById} from '../outlet/core/_requests'
import BookingForm from './components/BookingForm'
import {FormStateProvider, useBookingFormState} from './core/FormStateProvider'
import {ModalProvider, useSelectTreatmentModal} from './core/_ModalProvider'
import {createOrder} from './core/_requests'

const OrderPage: FC = () => {
  const {id} = useParams()
  const [outlet, setOutlet] = useState<Outlet | undefined>(undefined)
  const {showSuccessModal} = useSelectTreatmentModal()
  const {setIsLoading} = useBookingFormState()

  const getOutletData = async () => {
    try {
      const res = await getOutletById(id)
      if (res?.response?.code === RESP.SUCCESS) {
        setOutlet(res?.response?.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (id) getOutletData()
  }, [id])

  const handleCreateOrder = async (body: any) => {
    try {
      const res = await createOrder(body)
      if (res?.response?.code === RESP.SUCCESS) {
        showSuccessModal()
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div className='container z-10'>
        <div className='row justify-content-center my-5 gap-5'>
          <div className='col-12 d-flex justify-content-center'>
            <img src={toAbsoluteUrl('/media/logos/logo-alaya.png')} className='w-200px img-fluid' />
          </div>
          <div className='col-12 col-md-8 col-lg-6'>
            <div className='card py-4 px-5 shadow-wit-2 bg-illustration-order'>
              <div className='text-center'>
                <h1 className='fw-bolder mt-4'>Booking Reservation</h1>
                <p className='text-gray-600'>
                  <SVG src={toAbsoluteUrl('/media/svg/orders/location_tick.svg')} /> Anda berada di{' '}
                  <b className='text-gray-800'>Outlet {outlet?.name}</b>
                </p>
              </div>
              {Boolean(id) && <BookingForm outlet_id={id || ''} handleSubmit={handleCreateOrder} />}
              <Link to='/privacy-policy' className='w-100 text-center text-info mt-3 fs-5 fw-bold'>
                Privacy Policy
              </Link>
              <img
                src={toAbsoluteUrl('/media/illustrations/Ilustration_order.png')}
                className='w-200px img-fluid position-absolute backdrop-order bottom-0 z-9'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const OrderWrapper: FC = () => {
  return (
    <>
      <FormStateProvider>
        <ModalProvider>
          <OrderPage />
        </ModalProvider>
      </FormStateProvider>
    </>
  )
}

export default OrderWrapper
