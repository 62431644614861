import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'

export type FilterOutlet = {
  set_name?: boolean
  name?: string
  set_phone_number?: boolean
  phone_number?: string
  set_email?: boolean
  email?: string
  set_brand_id?: boolean
  brand_id?: string
  set_sub_brand_id?: boolean
  sub_brand_id?: string
  set_region_id?: boolean
  region_id?: string
  set_city_id?: boolean
  city_id?: string
  set_outlet_code?: boolean
  outlet_code?: string
}

export const initialFilterOutlet: BaseRequest<FilterOutlet> = {
  filter: {
    set_name: false,
    name: '',
    set_phone_number: false,
    phone_number: '',
    set_email: false,
    email: '',
    set_sub_brand_id: false,
    sub_brand_id: '',
    set_brand_id: false,
    brand_id: '',
    set_region_id: false,
    region_id: '',
    set_city_id: false,
    city_id: '',
    set_outlet_code: false,
    outlet_code: '',
  },
  limit: 10,
  page: 1,
  order: 'created_at',
  sort: 'DESC',
}

export type Outlet = {
  guid?: string
  name?: string
  email?: string
  region?: Region
  brand_id?: string
  group_id?: string
  image_url?: string
  brand_name?: string
  sub_brand_name?: string
  created_at?: Date
  created_by?: string
  group_name?: string
  updated_at?: Date
  updated_by?: string
  url_tiktok?: string
  description?: string
  outlet_code?: string
  url_website?: string
  phone_number?: string
  sub_brand_id?: string
  url_instagram?: string
  outlet_organization?: OutletOrganization
  outlet_configuration?: OutletConfiguration
}

export type Region = {
  area?: string
  address?: string
  city_id?: string
  city_name?: string
  region_id?: string
  region_name?: string
}

export type OutletOrganization = {
  guid?: string
  created_at?: Date
  created_by?: string
  updated_at?: Date
  updated_by?: string
  outlet_guid?: string
  outlet_organization_data?: OutletOrganizationData
  outlet_organization_guid?: string
}

export type OutletOrganizationData = {
  guid?: string
  bank_name?: string
  created_at?: Date
  created_by?: string
  updated_at?: Date
  updated_by?: string
  bank_account?: string
  organization_name?: string
}

export type OutletConfiguration = {
  guid?: string
  email?: EmailConfiguration[]
  created_at?: Date
  created_by?: string
  updated_at?: Date
  updated_by?: string
  tax_amount?: number
  buffer_time?: number
  outlet_guid?: string
  expired_time?: number
  service_amount?: number
  tax_order_number?: number
}

export type OutletRequest = {
  outlet_code?: string
  name?: string
  area?: string
  region_id?: string
  city_id?: string
  address?: string
  phone_number?: string
  email?: string
  url_website?: string
  url_instagram?: string
  url_tiktok?: string
  description?: string
  image_url?: string
}

export type CreateOutletRequest = {
  group_id?: string
  brand_id?: string
  outlet_code?: string
  name?: string
  area?: string
  region_id?: string
  city_id?: string
  address?: string
  phone_number?: string
  email?: string
  url_website?: string
  url_instagram?: string
  url_tiktok?: string
  description?: string
  image_url?: string
}

export const initialCreateOutletRequest: CreateOutletRequest = {
  group_id: '',
  brand_id: '',
  outlet_code: '',
  name: '',
  area: '',
  region_id: '',
  city_id: '',
  address: '',
  phone_number: '',
  email: '',
  url_website: '',
  url_instagram: '',
  url_tiktok: '',
  description: '',
  image_url: '',
}

export type FilterOutletOrganization = {
  set_organization_name?: boolean
  organization_name?: string
  set_bank_name?: boolean
  bank_name?: string
  set_bank_account?: boolean
  bank_account?: string
}

export const initialFilterOutletOrganization: BaseRequest<FilterOutletOrganization> = {
  filter: {
    set_organization_name: false,
    organization_name: '',
    set_bank_name: false,
    bank_name: '',
    set_bank_account: false,
    bank_account: '',
  },
  limit: 1000,
  page: 1,
  order: 'created_at',
  sort: 'ASC',
}

export type OrganizationRequest = {
  organization_name?: string
  bank_name?: string
  bank_account?: string
}

export type Organization = {
  guid?: string
  bank_name?: string
  created_at?: Date
  created_by?: string
  updated_at?: Date
  updated_by?: string
  bank_account?: string
  organization_name?: string
}

export type CreateConfigurationRequest = {
  outlet_guid?: string
  email?: EmailConfiguration[]
  tax_amount?: string | undefined
  buffer_time?: number
  expired_time?: number
  service_amount?: string
  tax_order_number?: number
}

export type UpdateConfigurationRequest = {
  email?: EmailConfiguration[]
  tax_amount?: string | undefined
  buffer_time?: number
  expired_time?: number
  service_amount?: string
  tax_order_number?: number
}

export type EmailConfiguration = {
  type?: string
  email?: string
  custom_field?: string | null
}

export const initialCreateOutletConfiguration: CreateConfigurationRequest = {
  outlet_guid: '',
  email: [],
  tax_amount: '',
  tax_order_number: 1,
  service_amount: '',
  buffer_time: 0,
  expired_time: 0,
}

export const initialUpdateOutletConfiguration: UpdateConfigurationRequest = {
  email: [],
  tax_amount: '',
  tax_order_number: 1,
  service_amount: '',
  buffer_time: 0,
  expired_time: 0,
}

export const initialEmailConfiguration: EmailConfiguration = {
  type: '',
  email: '',
  custom_field: null,
}

export type FilterPartnerOrganization = {
  set_organization_name?: boolean
  organization_name?: string
  set_name?: boolean
  name?: string
  set_phone_number?: boolean
  phone_number?: string
  set_email?: boolean
  email?: string
  set_brand_cluster?: boolean
  brand_cluster?: string
}

export const initialFilterPartnerOrganization: BaseRequest<FilterPartnerOrganization> = {
  filter: {
    set_organization_name: false,
    organization_name: '',
    set_name: false,
    name: '',
    set_phone_number: false,
    phone_number: '',
    set_email: false,
    email: '',
    set_brand_cluster: false,
    brand_cluster: '',
  },
  limit: 10,
  page: 1,
  order: 'created_at',
  sort: 'ASC',
}

export type PartnerOrganization = {
  guid?: string
  name?: string
  email?: string
  address?: string
  created_at?: Date
  created_by?: string
  updated_at?: Date
  updated_by?: string
  phone_number?: string
  brand_cluster?: string
  organization_name?: string
  pic_partner_organization?: PicPartnerOrganization
}

export type PicPartnerOrganization = {
  guid?: string
  surname?: string
  full_name?: string
  job_title?: string
  created_at?: Date
  created_by?: string
  updated_at?: Date
  updated_by?: string
  partner_organization_guid?: string
}

export type CreatePartnershipContractRequest = {
  start_date?: Date
  end_date?: Date
  outlet_guid?: string
  partner_organization_guid?: string
  share_profit_outlet?: string
  share_profit_partner?: string
  service_charge_outlet?: string
  service_charge_partner?: string
  signed_by?: string
  remarks?: string
  file_upload?: FileUpload[]
  additional?: string
}

export type UpdatePartnershipContractRequest = {
  start_date?: Date
  end_date?: Date
  share_profit_outlet?: string
  share_profit_partner?: string
  service_charge_outlet?: string
  service_charge_partner?: string
  signed_by?: string
  remarks?: string
  file_upload?: FileUpload[]
  additional?: string
}

export type FileUpload = {
  url?: string
  file_name?: string
  file_extension?: string
}

export type OutletResponse = BaseResponse<Array<Outlet>>
export type OrganizationResponse = BaseResponse<Array<Organization>>
export type PartnerOrganizationResponse = BaseResponse<Array<PartnerOrganization>>
