import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'

export type RatingByTherapist = {
  outlet?: Outlet
  employee?: Employee
  total_qty?: number
  avg_rating?: number
  total_order?: number
  total_grandtotal?: number
}

export type Employee = {
  guid?: string
  name?: string
}

export type Outlet = {
  area?: string
  guid?: string
  name?: string
  city_id?: string
  city_name?: string
  region_id?: string
  region_name?: string
}

export type FilterRatingByTherapist = {
  set_date?: boolean
  end_date_value?: string
  start_date_value?: string
  outlet_guid_value?: any[]
  set_outlet_guid?: boolean
  employee_name_value?: string
  set_employee_name?: boolean
  area_value?: string
  set_area?: boolean
  region_value?: string
  set_region?: boolean
  city_value?: string
  set_city?: boolean
  brand_value?: string
  set_brand?: boolean
  sub_brand_value?: string
  set_sub_brand?: boolean
}

export type ListRatingByTherapistResponse = BaseResponse<Array<RatingByTherapist>>
export type FilterRatingByTherapistRequest = BaseRequest<FilterRatingByTherapist>

const initialFilterRatingByTherapist: FilterRatingByTherapistRequest = {
  filter: {
    set_date: false,
    end_date_value: '',
    start_date_value: '',
    outlet_guid_value: [],
    set_outlet_guid: false,
    employee_name_value: '',
    set_employee_name: false,
    area_value: '',
    set_area: false,
    region_value: '',
    set_region: false,
    city_value: '',
    set_city: false,
    brand_value: '',
    set_brand: false,
    sub_brand_value: '',
    set_sub_brand: false,
  },
  limit: 100,
  page: 1,
  order: 'created_at',
  sort: 'DESC',
}

export {initialFilterRatingByTherapist}
