/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { BarChart } from '@/_metronic/partials/content/chart/BarChart'
import { QueryRequestProvider as RatingQueryRequestProvider } from '@/app/modules/rating-by-therapist/core/QueryRequestProvider'
import { QueryResponseProvider as RatingQueryResponseProvider } from '@/app/modules/rating-by-therapist/core/QueryResponseProvider'
import {
  QueryRequestProvider,
  useQueryRequest,
} from '@/app/modules/report-trend-analysis/core/QueryRequestProvider'
import {
  QueryResponseProvider,
  useQueryResponseData,
} from '@/app/modules/report-trend-analysis/core/QueryResponseProvider'
import { PageTitle } from '../../../../_metronic/layout/core'
import { TopArea } from './components/TopArea'
import { TopCity } from './components/TopCity'
import { TopEmployees } from './components/TopEmployee'
import { TopOutlet } from './components/TopOutlet'
import { TopQueryRequestProvider } from './core/QueryRequestTopProvider'
import { AreaQueryResponseProvider, useAreaQueryResponseData } from './core/QueryResponseAreaProvider'
import { CityQueryResponseProvider, useCityQueryResponseData } from './core/QueryResponseCityProvider'
import {
  OutletQueryResponseProvider,
  useOutletQueryResponseData,
} from './core/QueryResponseOutletProvider'

const DashboardPage: FC = () => {
  const {updateState} = useQueryRequest()
  const data = useQueryResponseData()
  const dataCity = useCityQueryResponseData()
  const dataArea = useAreaQueryResponseData()
  const currentDate = new Date()
  const arrayMonth = [
    (currentDate.getMonth() - 4).toString(),
    (currentDate.getMonth() - 3).toString(),
    (currentDate.getMonth() - 2).toString(),
    (currentDate.getMonth() - 1).toString(),
    currentDate.getMonth().toString(),
    (currentDate.getMonth() + 1).toString(),
  ]

  useEffect(() => {
    updateState({
      month: arrayMonth,
    })
  }, [])

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/* begin::Col */}
        <div className='col-md-6 col-lg-6 col-xl-6 mb-md-5 mb-xl-3'>
          <BarChart
            className='area-wib shadow-wit'
            color='primary'
            title='Sales'
            series={[
              {
                name: 'Sales',
                data: data?.trend_transaction?.map((item) => item.total_sales),
              },
            ]}
            categories={data?.trend_transaction?.map((item) =>
              item.month?.split('2023')[0].substring(0, 3).trim()
            )}
            total={data?.summary_trend?.sales}
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-md-6 col-lg-6 col-xl-6 mb-md-5 mb-xl-3'>
          <BarChart
            className='area-wib shadow-wit'
            color='success'
            title='Treatments'
            series={[
              {
                name: 'Quantity',
                data: data?.trend_product?.map((item) => item.total_qty),
              },
            ]}
            categories={data?.trend_product?.map((item) =>
              item.month?.split('2023')[0].substring(0, 3).trim()
            )}
            total={data?.summary_trend?.product}
            isCurrency={false}
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-12'>
          <TopEmployees className='flex-fill col-xl-12' />
        </div>
        <div className='d-flex flex-row gap-4'>
          <TopOutlet className='flex-fill col-xl-4' />
          <TopCity className='flex-fill col-xl-4' />
          <TopArea className='flex-fill col-xl-4' />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 mb-md-5 mb-xl-3 mt-0'>
          <AccountPayable />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 mb-md-5 mb-xl-3 mt-0'>
          <AccountMovable />
        </div> */}
        {/* end::Col */}

        {/* <div className='col-4 mb-md-5 mb-xl-3'> */}
        {/* begin::Col */}
        {/* <InventoryAsset
            className='card-xl mb-xl-8 shadow-wit-2'
            // className='card-xl-stretch mb-xl-8'
            svgIcon='chart-simple'
            color='white'
            iconColor='primary'
            title='Rp 500.000.000'
            description='Inventory Asset'
          />
          <InventoryCategory className='mb-5 mb-xl-10 shadow-wit-2' /> */}
        {/*   end::Col */}
        {/* </div> */}
        {/* <div className='col-8 mb-md-5 mb-xl-3'>
          <InventoryRestock className='card-xxl-stretch mb-3 mb-xl-8 shadow-wit-2' />
        </div> */}
      </div>
      {/* end::Row */}
    </>
  )
}
const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <RatingQueryRequestProvider>
        <QueryRequestProvider>
          <TopQueryRequestProvider>
            <RatingQueryResponseProvider>
              <QueryResponseProvider>
                <OutletQueryResponseProvider>
                  <CityQueryResponseProvider>
                    <AreaQueryResponseProvider>
                      <PageTitle breadcrumbs={[]}>
                        {intl.formatMessage({id: 'MENU.HOME'})}
                      </PageTitle>
                      <DashboardPage />
                    </AreaQueryResponseProvider>
                  </CityQueryResponseProvider>
                </OutletQueryResponseProvider>
              </QueryResponseProvider>
            </RatingQueryResponseProvider>
          </TopQueryRequestProvider>
        </QueryRequestProvider>
      </RatingQueryRequestProvider>
    </>
  )
}

export { DashboardWrapper }

