import { ReportSalesBudgetListToolbar } from './ReportSalesBudgetListToolbar'

const ReportSalesBudgetListHeader = () => {
  return (
    <div className='border-0'>
      <div className='card-toolbar'>
        <ReportSalesBudgetListToolbar />
      </div>
    </div>
  )
}

export { ReportSalesBudgetListHeader }

