// @ts-nocheck
import {Column} from 'react-table'
import {UserCustomHeader} from './UserCustomHeader'
import {User} from '../../core/_models'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Brand' className='min-w-125px' />,
    id: 'brand',
    accessor: 'brand',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Sub Brand' className='min-w-125px' />
    ),
    id: 'sub_brand',
    accessor: 'sub_brand',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Outlet' className='min-w-125px' />
    ),
    id: 'outlet',
    accessor: 'outlet',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Period' className='min-w-125px' />
    ),
    accessor: 'period',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Year' className='min-w-125px' />
    ),
    accessor: 'year',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actual Sales' className='min-w-125px' />
    ),
    accessor: 'actual_sales',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Forecast' className='min-w-125px' />
    ),
    accessor: 'forecast',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actual Treatments' className='min-w-125px' />
    ),
    accessor: 'actual_treatments',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Forecast' className='min-w-125px' />
    ),
    accessor: 'forecast2',
  },
]

export {usersColumns}
