/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTCard} from '@/_metronic/helpers/components/KTCard'
import {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import RushHourDetail from './RushHourDetail'
import RushHourOverview from './RushHourOverview'
import {FilterReportRushHourModal} from './components/filter/FilterReportRushHourModal'
import {ReportRushHourListHeader} from './components/filter/ReportRushHourListHeader'
import {
  FilterReportRushHourProvider,
  useFilterReportRushHour,
} from './core/FilterReportRushHourProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponseData} from './core/QueryResponseProvider'
import {RushHourTable} from './rush-hour-list/RushHourTable'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Report',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Sales',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const RushHourPage: FC = () => {
  const data = useQueryResponseData()
  const {closeModal, showFilterReportRushHourModal} = useFilterReportRushHour()

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>Report Rush Hour</PageTitle>
      <KTCard className='py-4 shadow-wit-2'>
        <ReportRushHourListHeader />
      </KTCard>
      <RushHourOverview data={data} />
      <RushHourTable className='shadow-wit-2 mb-10' data={data?.rush_hour_summary} />
      <FilterReportRushHourModal
        show={showFilterReportRushHourModal}
        closeModal={() => closeModal()}
      />
    </>
  )
}

const RushHourWrapper: FC = () => {
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <FilterReportRushHourProvider>
            <RushHourPage />
          </FilterReportRushHourProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export {RushHourWrapper}
